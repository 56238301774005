import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  CardContent,
  CardMedia,
  Typography,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  DialogActions,
  Dialog,
  Stack,
} from "@mui/material";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import ProductRepository from "../../../data/repository/ProductRepository";
import MercadopagoIcon from "../../../assets/images/logo-mercadopago.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SuccessDialog from "../SuccessDialog";
import DialogRequestAllSeries from "./DialogRequestAllSeries";
import CartRepository from "../../../data/repository/CartRepository";
import ErrorDialog from "../ErrorDialog";
import LoginModal from "../LoginModal";
import { useAuth } from "../../contexts/AuthContext";
import { useCart } from "../../contexts/CartContext";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import TokenRepository from "../../../data/repository/TokenRepository";
import CollateralRepository from "../../../data/repository/CollateralRepository";
import StripeLogo from "../checkout/stripeLogo";

const ProductDetailComponent = (props) => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const typeOfProduct = location.pathname.startsWith("/product/detail/")
    ? "product"
    : location.pathname.startsWith("/collateral/detail/")
    ? "collateral"
    : location.pathname.startsWith("/token/detail/")
    ? "token"
    : null;

  const { getCartList } = useCart();

  const productRepository = new ProductRepository();
  const tokenRepository = new TokenRepository();
  const collateralRepository = new CollateralRepository();
  const [product, setProduct] = useState(null);
  const [requestDialog, setRequestDialog] = useState(null);
  const [buyCollateralDialog, setBuyCollateralDialog] = useState({
    open: false,
    id: null,
  });
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [collateralAdquireLoading, setCollateralAdquireLoading] =
    useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [distanceTo, setDistanceTo] = useState("...");

  const { isAuthenticated, roles, userId } = useAuth();

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        var productData;
        if (typeOfProduct === "product") {
          productData = await productRepository.findById(id, i18n.language);
        }
        if (typeOfProduct === "collateral") {
          productData = await collateralRepository.findById(id, i18n.language);
        }
        if (typeOfProduct === "token") {
          productData = await tokenRepository.findById(id, i18n.language);
        }
        setProduct(productData);
      } catch (error) {
        navigate("/marketplace");
      }
    };
    fetchProductDetail();
  }, [id, i18n.language]);

  useEffect(() => {
    // Update the count down every 1 second
    if (product) {
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();
        const today = new Date(product.minRequestDate?.date).getTime();
        // Find the distance between now and the count down date
        var distanceWine = today - now;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distanceWine / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distanceWine % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor(
          (distanceWine % (1000 * 60 * 60)) / (1000 * 60)
        );
        var seconds = Math.floor((distanceWine % (1000 * 60)) / 1000);
        // If the count down is finished, write some text
        if (distanceWine < 0) {
          setDistanceTo(t("productDetail.yes"));
          clearInterval(x);
        } else {
          setDistanceTo(
            days + "d " + hours + "h " + minutes + "m " + seconds + "s"
          );
        }
      }, 1000);
    }
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!product) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <CircularProgress
          size={80}
          style={{
            color:
              typeOfProduct === "token"
                ? "#450D5F"
                : typeOfProduct === "collateral"
                ? "#160F31"
                : "#F36E1E",
          }}
        />
      </div>
    );
  }

  const isImage = (url) => {
    return [".jpg", ".jpeg", ".png", ".gif"].some((ext) => url.endsWith(ext));
  };

  const openRequestDialog = () => {
    setRequestDialog(true);
  };

  const cartRepository = new CartRepository();

  const handleLoginClick = () => {
    setLoginModalOpen(true);
  };

  const handleAddProduct = async (tokenProductId) => {
    try {
      var productId = typeOfProduct === "token" ? tokenProductId : id;
      var tokenId = typeOfProduct === "token" ? id : null;
      const response = await cartRepository.addProduct(productId, tokenId);
      if (!response.success) {
        console.error(response.message);
        setError(
          response.message === "cart.error.00001"
            ? t("marketplace.tokenError1")
            : response.message === "cart.error.00002"
            ? t("marketplace.tokenError2")
            : response.message === "cart.error.00003"
            ? t("marketplace.tokenError3")
            : response.message === "cart.error.00006"
            ? t("marketplace.tokenError6")
            : response.message === "cart.error.00007"
            ? t("marketplace.tokenError7")
            : response.message === "cart.error.00008"
            ? t("marketplace.tokenError8")
            : response.message === "cart.error.00009"
            ? t("marketplace.tokenError9")
            : "Error: " + response.message
        );
        return;
      }
      setSuccess(t("productDetail.success"));
      getCartList();
    } catch (error) {
      setError(t("productDetail.error"));
    }
  };

  const handleAdquireCollateral = async (collateralId) => {
    setCollateralAdquireLoading(true);
    try {
      const response = await collateralRepository.adquireCollateral(
        collateralId
      );
      if (!response.success) {
        console.error(response.message);
        setError(
          response.message === "collateral.buy.error.00001"
            ? t("productDetail.adquireError1")
            : response.message === "collateral.buy.error.00002"
            ? t("productDetail.adquireError2")
            : response.message === "collateral.buy.error.00003"
            ? t("productDetail.adquireError3")
            : response.message === "collateral.buy.error.00004"
            ? t("productDetail.adquireError4")
            : response.message === "collateral.buy.error.00005"
            ? t("productDetail.adquireError5")
            : "Error: " + response.message
        );
        setBuyCollateralDialog({ open: false, id: null });
        setCollateralAdquireLoading(false);
        return;
      }
      setSuccess(t("productDetail.adquireSuccess"));
    } catch (error) {
      setError(t("productDetail.adquireError"));
    } finally {
      setBuyCollateralDialog({ open: false, id: null });
      setCollateralAdquireLoading(false);
    }
  };

  return (
    <Container
      maxWidth="xl"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "1rem",
      }}
    >
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} md={6}>
          <Container
            sx={{
              display: { xs: "flex", sm: "none" },
              width: "300px",
              height: "300px",
            }}
          >
            {isImage(product.media[0].src) ? (
              <CardMedia
                component="img"
                image={product.media[0].src}
                alt={product.title}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "300px",
                  maxWidth: "300px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <CardMedia
                component="video"
                autoPlay
                muted
                loop
                src={product.media[0].src}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "300px",
                  maxWidth: "300px",
                  objectFit: "contain",
                }}
              />
            )}
          </Container>
          <Container
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="swiper-product-top"
            >
              {product.media?.map((image, index) => (
                <SwiperSlide key={index}>
                  {isImage(image.src) ? (
                    <CardMedia
                      component="img"
                      image={image.src}
                      alt={product.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "480px",
                        maxWidth: "480px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <CardMedia
                      component="video"
                      autoPlay
                      muted
                      loop
                      src={image.src}
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "480px",
                        maxWidth: "480px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="swiper-product-bottom"
            >
              {product.media?.map((image, index) => (
                <SwiperSlide key={index}>
                  {isImage(image.src) ? (
                    <CardMedia
                      component="img"
                      image={image.src}
                      alt={product.title}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <CardMedia
                      component="video"
                      autoPlay
                      muted
                      loop
                      src={image.src}
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography
              variant="h1"
              component="div"
              sx={{
                color: typeOfProduct === "token" ? "#450D5F" : "",
                fontWeight: "bold",
                fontSize: { xs: "20px", sm: "25px" },
                textTransform: "uppercase",
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              {product.name}
            </Typography>
            <Typography
              variant="h5"
              color="textSecondary"
              sx={{
                fontSize: { xs: "16px", sm: "25px" },
                textAlign: { xs: "center", sm: "left" },
              }}
              gutterBottom
            >
              {t(`productDetail.from`)}{" "}
              <span style={{ textTransform: "uppercase" }}>
                {product.cellarName}
              </span>
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              {product.description}
            </Typography>
            {product.features && (
              <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
                <Table>
                  <TableBody>
                    {Object.entries(product.features)?.map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell
                          style={{
                            textTransform: "capitalize",
                            padding: "0.5rem 1rem",
                          }}
                        >
                          {t(`productDetail.${key.toLowerCase()}`)}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0.5rem 1rem" }}
                          align="right"
                        >
                          {key === "presentation"
                            ? t(`productDetail.presentation1`) +
                              value +
                              t(`productDetail.presentation2`)
                            : value}
                        </TableCell>
                      </TableRow>
                    ))}
                    {typeOfProduct === "collateral" ? null : (
                      <TableRow>
                        <TableCell
                          style={{
                            textTransform: "capitalize",
                            padding: "0.5rem 1rem",
                          }}
                        >
                          {t(`productDetail.available`)}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0.5rem 1rem" }}
                          align="right"
                        >
                          {distanceTo}
                        </TableCell>
                      </TableRow>
                    )}
                    {typeOfProduct === "token" ? (
                      <>
                        <TableRow>
                          <TableCell
                            style={{
                              textTransform: "capitalize",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            {t(`marketplace.seller`)}
                          </TableCell>
                          <TableCell
                            style={{ padding: "0.5rem 1rem" }}
                            align="right"
                          >
                            {product.user?.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              textTransform: "capitalize",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            {t(`marketplace.token`)}
                          </TableCell>
                          <TableCell
                            style={{ padding: "0.5rem 1rem" }}
                            align="right"
                          >
                            {product.tokenNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              textTransform: "capitalize",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            {t(`marketplace.endDate`)}
                          </TableCell>
                          <TableCell
                            style={{ padding: "0.5rem 1rem" }}
                            align="right"
                          >
                            {product?.endDate?.date?.split(" ")[0]}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                    {typeOfProduct === "collateral" ? (
                      <>
                        <TableRow>
                          <TableCell
                            style={{
                              textTransform: "capitalize",
                              padding: "0.5rem 1rem",
                            }}
                          >
                            {t(`marketplace.liters`)}
                          </TableCell>
                          <TableCell
                            style={{ padding: "0.5rem 1rem" }}
                            align="right"
                          >
                            {product.liters}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Box
              mt={2}
              display="flex"
              flexWrap="wrap"
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
              gap={1}
            >
              {product.dataSheet && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ maxHeight: "45px" }}
                  href={product.dataSheet}
                  className={`btn-outline-gradient ${
                    typeOfProduct === "token"
                      ? "btn-outline-gradient_token"
                      : ""
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("productDetail.technicalSheet")}
                </Button>
              )}

              {product?.invCertificate && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ maxHeight: "45px" }}
                  href={product.invCertificate}
                  className={`btn-outline-gradient ${
                    typeOfProduct === "token"
                      ? "btn-outline-gradient_token"
                      : ""
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("productDetail.invCertificate")}
                </Button>
              )}

              {/*product.awards && (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{maxHeight: '45px'}}
                                href={product.awards}
                                className={"btn-outline-gradient"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('productDetail.awards')}
                            </Button>
                        )*/}

              {product.existenceProof && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ maxHeight: "45px" }}
                  href={product.existenceProof}
                  className={`btn-outline-gradient ${
                    typeOfProduct === "token"
                      ? "btn-outline-gradient_token"
                      : ""
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("productDetail.proofOfExistence")}
                </Button>
              )}

              {product.trustDoc && typeOfProduct === "collateral" && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ maxHeight: "45px" }}
                  href={product.trustDoc}
                  className={
                    "btn-outline-gradient btn-outline-gradient_collateral"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("productDetail.trustContract")}
                </Button>
              )}

              {product.guarantorDoc && typeOfProduct === "collateral" && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ maxHeight: "45px" }}
                  href={product.guarantorDoc}
                  className={
                    "btn-outline-gradient btn-outline-gradient_collateral"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("productDetail.guaranteedCollateral")}
                </Button>
              )}
            </Box>
            {typeOfProduct !== "collateral" && (
              <Typography
                variant="h5"
                color="textSecondary"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "2rem",
                }}
                gutterBottom
              >
                {t("productDetail.price")}: {product.localPrice}
                {/*<span style={{ fontSize: '14px', color: '#878787' }}>| {product.price} USDC</span>*/}
              </Typography>
            )}

            {typeOfProduct === "collateral" && (
              <Typography
                variant="h5"
                color="textSecondary"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "2rem",
                }}
                gutterBottom
              >
                {t("productDetail.price")}: {product.localPrice}
              </Typography>
            )}

            {typeOfProduct === "token" &&
              product.points > 0 &&
              roles[0] === "ROLE_USER" && (
                <Typography variant="body2" color="textSecondary">
                  {t("productDetail.pointsTransfer", {
                    points: product.points,
                  })}
                </Typography>
              )}

            {typeOfProduct === "product" && roles[0] === "ROLE_USER" && (
              <Typography variant="body2" color="textSecondary">
                {t("productDetail.pointsTransfer", {
                  points: parseInt(product.price * product.pointsPercentage),
                })}
              </Typography>
            )}

            {roles[0] === "ROLE_USER" && typeOfProduct === "product" ? (
              <Link
                onClick={openRequestDialog}
                variant="h5"
                color="textSecondary"
                mt={2}
                style={{
                  fontSize: "14px",
                  color: "#F36E1E",
                  display: "block",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                gutterBottom
              >
                {t("productDetail.buyAllNFTs")}
              </Link>
            ) : null}

            <Box
              mt={3}
              display="flex"
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
              gap={2}
            >
              {roles[0] !== "ROLE_FINANCIAL" && userId !== product.userId && (
                <>
                  <Stack spacing={2}>
                    {typeOfProduct === "collateral" &&
                      product.tokens.map((m, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                          className="btn-gradient_collateral"
                          disabled={m.isOnSale === false}
                          onClick={
                            isAuthenticated
                              ? () =>
                                  setBuyCollateralDialog({
                                    open: true,
                                    id: m.id,
                                  })
                              : handleLoginClick
                          }
                        >
                          {t("productDetail.adquire")} TOKEN {m.lotSubNumber}
                        </Button>
                      ))}
                  </Stack>
                  {typeOfProduct === "token" && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                      className={"btn-gradient_token"}
                      onClick={
                        isAuthenticated
                          ? () => handleAddProduct(product.productId)
                          : handleLoginClick
                      }
                    >
                      {t("productDetail.buy")}
                    </Button>
                  )}

                  {typeOfProduct === "product" && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                      className={"btn-gradient"}
                      onClick={
                        isAuthenticated
                          ? () => handleAddProduct(product.productId)
                          : handleLoginClick
                      }
                    >
                      {t("productDetail.buy")}
                    </Button>
                  )}

                  {typeOfProduct === "collateral" ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                      className={
                        typeOfProduct !== "token"
                          ? "btn-gradient"
                          : "btn-gradient_token"
                      }
                      onClick={() =>
                        navigate(
                          `/marketplace-benefits?product=${
                            typeOfProduct === "token" ? product.productId : id
                          }`
                        )
                      }
                    >
                      {t("productDetail.viewBenefits")}
                    </Button>
                  )}
                </>
              )}

              <WhatsAppIcon
                fontSize="large"
                onClick={() =>
                  window.open(
                    `whatsapp://send?text=${window.location.href}`,
                    "_blank",
                    "nofollow noopener"
                  )
                }
                sx={{
                  color: "black",
                  width: "44px",
                  height: "44px",
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                  "&:active": { opacity: 0.6 },
                }}
              />
            </Box>
            {typeOfProduct !== "collateral" && (
              <>
                <Typography
                  variant="body2"
                  style={{ marginTop: "1rem", display: "block" }}
                >
                  {t("productDetail.buyTokenAlert")}
                </Typography>
                {/*<Typography variant="h6" onClick={() => window.open(`whatsapp://send?text=${window.location.href}`, "_blank", "nofollow noopener")} 
                            sx={{marginTop: '1rem', cursor: 'pointer', "&:hover": { opacity: 0.8 }, "&:active": { opacity: 0.6 }, fontSize: '14px', color: 'black', display: 'block', fontStyle: 'italic', fontWeight: 'bold', textDecoration: 'none'}} gutterBottom
                        >
                            {t('productDetail.whatsapp')}
                        </Typography>*/}
                {
                  <>
                    <hr style={{ marginTop: "2rem", marginBottom: "1rem" }} />
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "1rem",
                        textAlign: { xs: "center", sm: "left" },
                      }}
                      gutterBottom
                    >
                      {t("productDetail.paymentMethods")}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginTop: "1rem",
                        textAlign: { xs: "center", sm: "left" },
                      }}
                      gutterBottom
                    >
                      {t("productDetail.creditOrDebit")}
                    </Typography>
                    <img
                      src={MercadopagoIcon}
                      alt={"mercadopago"}
                      style={{
                        display: "block",
                        marginTop: "0rem",
                        maxWidth: "120px",
                      }}
                    />
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginTop: "1rem",
                        textAlign: { xs: "center", sm: "left" },
                      }}
                      gutterBottom
                    >
                      {t("productDetail.withStripe")}
                    </Typography>
                    <StripeLogo width={100} fill="#7069fe" />
                  </>
                }
              </>
            )}
          </CardContent>
        </Grid>
      </Grid>

      {typeOfProduct === "collateral" && (
        <Dialog
          open={buyCollateralDialog?.open === true}
          onClose={() => setBuyCollateralDialog({ open: false, id: null })}
          aria-labelledby="buy-collateral-dialog"
        >
          <DialogTitle id="buy-collateral-title">
            {t("productDetail.buyCollateralTitle")}
          </DialogTitle>
          <DialogContent>{t("productDetail.buyCollateralText")}</DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "0.75rem",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ width: { xs: "100%", sm: "50%" } }}
              onClick={
                collateralAdquireLoading
                  ? null
                  : () => handleAdquireCollateral(buyCollateralDialog.id)
              }
              className={"btn-gradient_collateral"}
              disabled={collateralAdquireLoading}
            >
              {t("productDetail.buyCollateralButton")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <DialogRequestAllSeries
        open={!!requestDialog}
        onClose={() => setRequestDialog(null)}
        productId={id}
        productName={product.name}
      />

      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        alertTitle={t("productDetail.errorTitle")}
        alertMessage={error}
        dark={false}
        typeOfProduct={typeOfProduct}
      />

      <SuccessDialog
        open={!!success}
        onClose={() => setSuccess(null)}
        alertTitle={t("productDetail.infoTitle")}
        alertMessage={success}
        dark={false}
        typeOfProduct={typeOfProduct}
      />

      {!isAuthenticated ? (
        <LoginModal
          open={loginModalOpen}
          onClose={() => setLoginModalOpen(null)}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default ProductDetailComponent;
